@tailwind base;
@tailwind components;
@tailwind utilities;

p:after {
  content:"";
  display:inline-block;
  width:0px;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto') url(./assets/fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: 'Segoe UI';
  src: url(./assets/fonts/SegoeUI/Segoe\ UI.ttf);
}
.text-input {
    @apply shadow rounded appearance-none h-14 font-sans text-xl text-blue border border-gray-light leading-tight mb-4 px-3 focus:outline-blue;
  }
  
  .title-small {
    @apply font-sans font-bold text-2xl;
  }
  
  .title-secondary {
    @apply font-sans font-bold text-3xl
  }
  
  .pm-link {
    @apply font-sans text-blue text-xl cursor-pointer flex justify-start items-center leading-6 py-2
  }
  
  .link-icon {
    @apply fill-current text-blue cursor-pointer
  }